import {
  selectConfiguration,
  setError,
  useAppDispatch,
  useAppSelector,
  useLazyGetDocumentsUploadedQuery,
  useLazyGetIdDebranchementQRQuery,
} from '@store';
import { useEffect, useState } from 'react';
import { EModalState } from './ModalQRCode/ModalQRCode';
import {
  useListenerSwitchMobile,
  useParcoursType,
  usePersistingState,
} from '@hooks';
import { EventSwitchDevice, FINAL_EVENTS_SWITCH_DEVICE } from '@types';
import { getEnv } from '@utils';

const useSwitchDevice = () => {
  const { souscriptionId, initAppUrlParams } =
    useAppSelector(selectConfiguration);

  const [urlQRCode, setUrlQRCode] = useState('');
  const [isModalQRCodeVisible, setIsModalQRCodeVisible] = useState(false);
  const [isLoadingQRCodeButton, setIsLoadingQRCodeButton] = useState(false);
  const dispatch = useAppDispatch();
  const [triggerGetIdDebranchementQRCode, resultGetIdDebranchementQRCode] =
    useLazyGetIdDebranchementQRQuery();

  const [triggerDocumentsUploaded, resultDocumentsUploaded] =
    useLazyGetDocumentsUploadedQuery();

  const [isSwitchMobileConnected, setIsSwitchMobileConnected] = useState(false);
  const [modalState, setModalState] = usePersistingState<EModalState>(
    'modalSwitchDeviceState',
    EModalState.INITIAL
  );
  const { isParcoursNominal, isParcoursSwitchDevice } = useParcoursType();

  const [
    isFinishUploadMobile,
    setIsFinishUploadMobile,
    getIsFinishUploadMobile,
  ] = usePersistingState<boolean>('isFinishUploadMobile', false);

  const {
    triggerListenerSwitchMobile,
    responseListenerSwitchMobile,
    sendAddEventSwitchMobile,
    interrupteListener,
  } = useListenerSwitchMobile();

  const [
    isParcoursSwitchDeviceNotInitialized,
    setIsParcoursSwitchDeviceNotInitialized,
    getParcoursSwitchDeviceNotInitialized,
  ] = usePersistingState<boolean | null>(
    'listener-isParcoursSwitchDeviceInitialized',
    true
  );

  const [
    isUploadSwitchDeviceKO,
    setIsUploadSwitchDeviceKO,
    getIsUploadSwitchDeviceKO,
  ] = usePersistingState<boolean>('listener-isTelechargementInterrompu', false);

  useEffect(() => {
    //Get documents parameters
    if (!souscriptionId) {
      return;
    }
    triggerDocumentsUploaded(souscriptionId);
  }, []);

  useEffect(() => {
    if (
      !isParcoursSwitchDevice() ||
      getIsUploadSwitchDeviceKO() ||
      getIsFinishUploadMobile()
    ) {
      return;
    }
    triggerListenerSwitchMobile();
  }, [, isParcoursSwitchDevice()]);

  useEffect(() => {
    if (
      !isParcoursSwitchDevice() ||
      getParcoursSwitchDeviceNotInitialized() === false
    ) {
      return;
    }
    sendAddEventSwitchMobile(EventSwitchDevice.CONNEXION);
    setIsParcoursSwitchDeviceNotInitialized(false);
  }, [, isParcoursSwitchDevice(), isParcoursSwitchDeviceNotInitialized]);

  useEffect(() => {
    responseListenerSwitchMobile?.forEach((evenement) => {
      if (evenement?.evenementRealise === EventSwitchDevice.CONNEXION) {
        setIsSwitchMobileConnected(true);
        setModalState(EModalState.SWITCH_MOBILE_CONNECTED);
      }
      if (!FINAL_EVENTS_SWITCH_DEVICE.includes(evenement?.evenementRealise)) {
        return;
      }
      if (isParcoursSwitchDevice()) {
        setIsUploadSwitchDeviceKO(true);
        endParcoursReInit();
        return;
      }
      switch (evenement?.evenementRealise) {
        case EventSwitchDevice.ANALYSE_OCR_KO:
          setModalState(EModalState.UNAVAIABLE_SERVICE_OCR);

          break;
        case EventSwitchDevice.INACTIVITE:
          setIsModalQRCodeVisible(false);
          dispatch(
            setError({
              code: 'timeOut',
            })
          );

          break;
        default:
          setModalState(EModalState.TELECHARGEMENT_REUSSI);
          if (souscriptionId) {
            triggerDocumentsUploaded(souscriptionId);
          }

          break;
      }
    });
  }, [responseListenerSwitchMobile]);

  useEffect(() => {
    if (
      !urlQRCode ||
      !isParcoursNominal() ||
      getEnv() === 'pprod' ||
      getEnv() === 'prod'
    ) {
      return;
    }
    console.log('URL Debranchement QRcode => ', urlQRCode);
  }, [urlQRCode]);

  useEffect(() => {
    if (
      !resultGetIdDebranchementQRCode?.data ||
      resultGetIdDebranchementQRCode?.status !== 'fulfilled'
    ) {
      return;
    }
    sendAddEventSwitchMobile(EventSwitchDevice.CREATION);
    triggerListenerSwitchMobile();
    setUrlQRCode(
      window.location.origin +
        '/' +
        (window.location.hash ?? '').split('?')[0] +
        '?' +
        initAppUrlParams +
        '&isDebranchementQRCode=true' +
        '&switchDeviceId=' +
        resultGetIdDebranchementQRCode?.data ?? ''
    );
    setIsLoadingQRCodeButton(false);
    setIsModalQRCodeVisible(true);
  }, [resultGetIdDebranchementQRCode]);

  const onClickFinishButton = () => {
    sendAddEventSwitchMobile(EventSwitchDevice.FERMETURE);
    setIsFinishUploadMobile(true);
  };

  const onClickQRCodeButton = () => {
    if (!souscriptionId) {
      return;
    }
    triggerGetIdDebranchementQRCode(souscriptionId);
    setIsLoadingQRCodeButton(true);
  };

  const onClickArretParcoursSwitchDevice = () => {
    if (souscriptionId) {
      triggerDocumentsUploaded(souscriptionId);
    }
    sendAddEventSwitchMobile(EventSwitchDevice.FERMETURE);
    endParcoursReInit();
  };

  const onClickCancel = () => {
    setModalState(
      isSwitchMobileConnected
        ? EModalState.SWITCH_MOBILE_CONNECTED
        : EModalState.INITIAL
    );
  };

  const onClickTelechargementReussi = () => {
    endParcoursReInit();
  };

  const endParcoursReInit = () => {
    setIsModalQRCodeVisible(false);
    setModalState(EModalState.INITIAL);
    setIsSwitchMobileConnected(false);
  };

  return {
    resultDocumentsUploaded,
    isFinishUploadMobile,
    urlQRCode,
    modalState,
    setModalState,
    isModalQRCodeVisible,
    isLoadingQRCodeButton,
    onClickFinishButton,
    onClickQRCodeButton,
    onClickArretParcoursSwitchDevice,
    onClickCancel,
    onClickTelechargementReussi,
    isUploadSwitchDeviceKO,
  };
};

export default useSwitchDevice;
