import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GlobalStyles } from '@styles';
import { Badge, Centering, ErrorBoundary, Theme } from '@components';
import './App.css';
import { Footer, Header, ParcoursSimulation } from '@containers';
import {
  AppWrapper,
  BreadcrumbWrapper,
  StyledBreadcrumb,
  FormationModeBadgeWrapper,
  FooterWrapper,
  StyledCentering,
} from './styles';
import { LABEL_FORMATION_MODE } from './messages';
import {
  useNavigate,
  IStep,
  useRedirection,
  useSendLogs,
  useParcoursType,
  useDetectMobileDevice,
} from '@hooks';
import {
  Informations,
  SelectOffer,
  Insurance,
  Summary,
  Signature,
  Documents,
  Choice,
  VerificationContactDetails,
} from '@layouts';
import useInitApp from './useInitApp';
import { isParcourSimulation } from '@utils';

const FormationModeWrapper = () => (
  <>
    <div className="formation-mode-top" />
    <div className="formation-mode-bottom" />
  </>
);

const App: React.FC = () => {
  const {
    theme,
    isFormation,
    isSimu,
    vendorId,
    urlFinSouscription,
    marqueBlanche,
    isRedirectable,
  } = useInitApp();

  useSendLogs();

  const [currentStep, setCurrentStep] = useState(0);
  const location = useLocation();
  const { steps, getCurrentStepIndex, needBlockRoute, navigate } =
    useNavigate();
  const { redirigerAccueil, logout } = useRedirection({
    urlFinSouscription,
  });
  const { isParcoursSwitchDevice } = useParcoursType();
  const { isMobileScreenSize } = useDetectMobileDevice();

  const forceNavigateBackIfNeeded = () => {
    if (
      isParcourSimulation(location.pathname) ||
      !needBlockRoute() ||
      isRedirectable() ||
      isParcoursSwitchDevice()
    ) {
      return;
    }
    navigate(-1);
  };

  useEffect(() => {
    forceNavigateBackIfNeeded();
    setCurrentStep(getCurrentStepIndex());
    window.scrollTo(0, 0);
  }, [location, window.location?.hash]);

  const getComponent = (path: string) => {
    switch (path) {
      case '/offre':
        return SelectOffer;
      case '/informations':
        return Informations;
      case '/assurance':
        return Insurance;
      case '/recapitulatif':
        return Summary;
      case '/signature':
        return Signature;
      case '/signature/choix':
        return Choice;
      case '/signature/verification':
        return VerificationContactDetails;
      case '/documents':
        return Documents;
      default:
        return SelectOffer;
    }
  };

  // Mode formation
  if (isFormation) {
    document.body.classList.add('formation-mode');
  }

  return (
    <>
      {theme && (
        <Theme theme={theme}>
          {isFormation && <FormationModeWrapper />}
          <AppWrapper className="app" isSimu={isSimu}>
            {isSimu ? (
              <ParcoursSimulation
                vendorName={marqueBlanche ? '' : theme?.vendor?.logo}
              />
            ) : (
              <StyledCentering>
                <ErrorBoundary>
                  <div>
                    <Header
                      vendorId={vendorId}
                      vendorName={marqueBlanche ? '' : theme?.vendor?.logo}
                      redirigerAccueil={redirigerAccueil}
                      logout={logout}
                    />
                    {(!isParcoursSwitchDevice() || !isMobileScreenSize()) && (
                      <BreadcrumbWrapper>
                        <StyledBreadcrumb
                          steps={steps}
                          currentStep={currentStep}
                        />
                      </BreadcrumbWrapper>
                    )}
                    {isFormation && (
                      <FormationModeBadgeWrapper>
                        <Badge variant="withDot">{LABEL_FORMATION_MODE}</Badge>
                      </FormationModeBadgeWrapper>
                    )}
                  </div>

                  <Routes>
                    {steps.map((step: IStep) =>
                      step.paths.map((path) => {
                        const StepComponent = getComponent(path);
                        return (
                          <Route
                            key={path}
                            path={path}
                            element={<StepComponent />}
                          />
                        );
                      })
                    )}
                  </Routes>
                  <GlobalStyles />
                </ErrorBoundary>
                <Centering>
                  <FooterWrapper>
                    <Footer />
                  </FooterWrapper>
                </Centering>
              </StyledCentering>
            )}
            <div id="modal"></div>
          </AppWrapper>
        </Theme>
      )}
    </>
  );
};

export default App;
